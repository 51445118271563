.c-list {
  &[data-list--marker] {
    padding-left: 1.4em;
  }
  &__item:not(:last-child) {
    margin-bottom: 2.4rem;
  }
  &__item-body {
    display: block;
  }

  &__item-head {
    font-size: 2rem;
  }
  &__item-body {
    margin-top: .8rem;
  }
  &__item-list {
    margin-top: 1.6rem;
    padding-left: 1.2em;
  }
}