.pg-mind-plan {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  border-radius: 3px;
  background-color: #fff;
  &__image {
    width: 260px;
    margin-right: 3.2rem;
  }
  &__list-item {
    display: flex;
    align-items: center;
    list-style: none;
  }
  &__list-item:not(:last-child) {
    margin-bottom: 2.4rem;
  }
  &__list-item__label {
    margin-right: 1.6rem;
    font-size: 2.8rem;
    white-space: nowrap;
  }
  &__caption {
    text-align: center;
  }
}