.pg-contact-policy {
  &__caption1 {
    margin-bottom: 3.2rem;
    text-align: center;
  }
  &__caption2 {
    text-align: center;
  }
  &__list {
    margin-bottom: 3.2rem;
  }
  &__list-item {
    position: relative;
    padding-bottom: 2.4rem;
  }
  &__list-item:not(:last-child):after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 960px;
    height: 1px;
    border-bottom: 1px dashed rgba(0,0,0,.1);
  }
}