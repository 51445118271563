.pg-voice-interview {
  &__person {
    margin-bottom: 3.2rem;
  }
  &__comment-item:not(:last-child) {
    margin-bottom: 3.2rem !important;
  }
  &__comment-item__title {
    display: flex;
    align-items: center;
    margin-bottom: .8rem;
  }
  &__comment-item__title:before {
    content: "";
    width: .8em;
    height: .8em;
    margin-right: .8rem;
    background-color: rgb(49, 136, 201);
  }
  &__comment-item__sentence {
    text-align: justify;
    line-height: 2;
  }
}