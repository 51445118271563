.pg-contact-inputarea {
  &__caption {
    margin-bottom: 2.4rem;
    text-align: center;
  }
  &__number {
    margin-bottom: 4.8rem;
    font-weight: bold;
    font-size: 1.8rem;
  }
  &__table {
    margin-bottom: 3.2rem;
  }
  &__table-textarea {
    min-height: 160px;
  }
  &__table-repletion {
    display: inline-block;
    margin-left: .8rem;
    transform: translateY(-.2rem);
  }
  &__btns {
    display: flex;
    justify-content: center;
  }
  &__btn {
    margin: 0 1.6rem;
  }
  &__btn:first-child {
    background-color: rgba(0,0,0,.15);
    color: #333;
  }
}