.l-footer {
  padding-bottom: 4rem;
  background-color: rgb(49, 136, 201);
  color: #fff;
  &__bg {
    overflow: hidden;
    width: 100%;
    height: 400px;
    margin-bottom: 4rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &[data-footer--nobg] {
    padding-top: 4rem;
  }
  &__wrap {
    display: flex;
  }

  &__sitemap {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    padding-left: 3.2rem;
    border-left: 1px solid rgba(255,255,255,.3);
    list-style: none;
  }
  &__sitemap >li {
    width: 33.3%;
  }
  &__sitemap-head {
    display: block;
    margin-bottom: .8rem;
    font-size: 1.6rem;
  }
  &__sitemap-submenu {
    list-style: none;
  }
  &__sitemap-submenu:nth-child(-n+3) {
    margin-bottom: 3.2rem;
  }
  &__sitemap-submenu a {
    opacity: .7;
  }
  &__sitemap-submenu a:hover {
    opacity: 1;
  }
  &__sitemap-submenu >li:not(:last-child) {
    margin-bottom: .4rem;
  }

  &__about {
    position: relative;
    margin-right: 3.2rem;
    white-space: nowrap;
  }
  &__about-name {
    margin-bottom: .8rem;
    font-size: 2.4rem;
    font-weight: bold;
    letter-spacing: .2em;
  }
  &__about-summary {
    margin-bottom: 4rem;
    line-height: 1.5;
  }
  &__about-caption {
    margin-bottom: .8rem;
    font-size: 1.2rem;
  }
  &__about-copy {
    display: block;
    line-height: 1.5;
  }
}