html {
  font-size: 62.5%;
}

body {
  min-width: 1200px;
  font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI','Avenir','Helvetica Neue','Helvetica','Arial',"游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: .15rem;
  color: #444;
}