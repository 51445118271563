.pg-office-address {
  &__gmap {
    margin-bottom: 2.4rem;
    width: 100%;
    height: 300px;
  }
  &__table {
    margin-bottom: 2.4rem;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  &__btn {
    margin-left: auto;
    margin-right: auto;
  }
}