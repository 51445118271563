.c-arrow {
  position: relative;
  padding-left: 1.2rem; 
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 8px;
    background: url(../../images/icon-arrow.svg) no-repeat center/cover;
  }
}

_:-ms-lang(x), .c-arrow:before {
  margin-top: -.2rem;
  width: 6px;
  height: 10px;
}