.pg-works-overview {
  &__photo {
    margin-bottom: 6.4rem;
    font-size: 0;
  }
  &__photo-item {
    width: calc(33.3% - 2.66rem) !important;
  }
  &__photo-item:not(:last-child) {
    margin-right: 4rem !important;
  }
  &__text-item {
    width: calc(50% - 3.2rem) !important;
  }
  &__text-item:not(:last-child) {
    margin-right: 6.4rem !important;
  }
  &__text-item__title {
    margin-bottom: 1.6rem;
    text-align: center;
    line-height: 1.5;
  }
  &__text-item__caption {
    line-height: 2;
    text-align: justify;
  }
}

_:-ms-lang(x), .pg-works-overview__text-item {
  width: calc(50% - 3.3rem) !important;
}