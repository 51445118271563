.c-grid {
  display: flex;
  flex-wrap: wrap;
  &[data-grid--around] {
    justify-content: space-around;
  }
  &[data-grid--col4] .c-grid__item {
    width: calc(25% - 1.2rem);
  }
  &[data-grid--col3] .c-grid__item {
    width: calc(33.3% - 1.06rem);
  }
  &[data-grid--col2] .c-grid__item {
    width: calc(50% - .8rem);
  }
  &[data-grid--col4] .c-grid__item:not(:nth-child(-n+4)),
  &[data-grid--col3] .c-grid__item:not(:nth-child(-n+3)),
  &[data-grid--col2] .c-grid__item:not(:nth-child(-n+2)) {
    margin-top: 1.6rem;
  }
  &[data-grid--col4] .c-grid__item:not(:nth-child(4n)),
  &[data-grid--col3] .c-grid__item:not(:nth-child(3n)),
  &[data-grid--col2] .c-grid__item:not(:nth-child(2n)) {
    margin-right: 1.6rem;
  }
}

_:-ms-lang(x), .c-grid[data-grid--col2] .c-grid__item {
  width: calc(50% - .9rem);
}