.pg-recruit-nextpage {
  display: flex;
  &__block {
    position: relative;
    width: 50%;
    transition: .5s ease;
  }
  &__block:hover {
    opacity: .7;
  }
  &__block-title {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
  }
  &__block-title:after {
    content: "";
    display: inline-block;
    margin-left: .8rem;
    width: 1em;
    height: 1em;
    background: url(../../images/icon-circlearrow.svg) no-repeat center/ cover;
  }
  &__block-bg {
    width: 100%;
    height: 100%;
  }
}