.pg-recruit-overview {
  text-align: justify;
  &__caption {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%); 
    font-size: 2rem;
    line-height: 1.8;
    color: #fff;
    text-shadow: 0 2px 6px rgba(0,0,0,0.8);
  }
  &__caption-block {
    display: block;
  }
  &__caption-block:first-child {
    margin-bottom: 1.6rem;
  }
}