.p-newslist {
  text-align: center;
  &__row {
    list-style: none;
  }
  &__row:not(:last-child) {
    margin-bottom: 2.4rem;
    padding-bottom: 2.4rem;
    border-bottom: 1px dashed rgba(0,0,0,.2);
  }
  &__date {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: .8rem;
  }
  &__date-repletion {
    margin-right: .8rem;
  }
  &__date-title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
  }
  &__detail {
    margin-top: 2.4rem;
  }
  &__detail-text {
    line-height: 2;
  }
  &__detail-btn {
    margin-top: 3.2rem;
  }
}