.u-margin--t64 {
  margin-top: 6.4rem;
}
.u-margin--t16 {
  margin-top: 1.6rem;
}

.u-margin--b80 {
  margin-bottom: 8rem;
}
.u-margin--b64 {
  margin-bottom: 6.4rem;
}
.u-margin--b48 {
  margin-bottom: 4.8rem;
}
.u-margin--b40 {
  margin-bottom: 4.8rem;
}
.u-margin--b32 {
  margin-bottom: 3.2rem;
}
.u-margin--b24 {
  margin-bottom: 2.4rem;
}
.u-margin--b16 {
  margin-bottom: 1.6rem;
}
.u-margin--b8 {
  margin-bottom: .8rem;
}

.u-margin--c {
  margin-left: auto;
  margin-right: auto;
}