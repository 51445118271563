.pg-mind-greeting {
  &__person {
    margin-bottom: 3.2rem;
  }
  &__text {
    line-height: 2;
  }
  &__text:not(:last-of-type) {
    margin-bottom: 1.6rem;
  }
}