.c-repletion {
  padding: .2rem .5rem;
  border-radius: 2px;
  font-size: 1rem;
  font-style: normal;
  color: #fff;
  &[data-reple--red] {
    background-color: rgb(189, 0, 0);
  }
  &[data-reple--blue] {
    background-color: rgb(49, 136, 201);
  }
}