.pg-toppage-slide {
  position: relative;
  margin-top: -10px;
  border-top: 10px solid rgb(49, 136, 201);
  font-size: 0;
  &__catch {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    font-size: 6rem;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 1rem;
    font-weight: bold;
    color: #fff;
  }
  &__mark {
    position: absolute;
    left: 50%;
    bottom: -50px;
    transform: translateX(-50%);
    z-index: 2;
    width: 100px;
    height: 100px;
  }
}