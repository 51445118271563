.u-inner--960 {
  width: 960px;
  margin-left: auto;
  margin-right: auto;
}
.u-inner--800 {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.u-inner--650 {
  width: 650px;
  margin-left: auto;
  margin-right: auto;
}
.u-inner--600 {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.u-inner--480 {
  width: 480px;
  margin-left: auto;
  margin-right: auto;
}