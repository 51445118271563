.pg-recruit-outline {
  &__title {
    margin-bottom: 2.4rem;
    text-align: center;
    line-height: 1;
  }
  &__title2 {
    margin-bottom: .8rem;
    font-size: 2rem;
  }
  &__caption {
    margin-bottom: 3.2rem;
    line-height: 1.8;
  }

  &__tablehide {
    overflow: hidden;
    height: 355px;
    transition: height .7s ease-out;
  }
  &__opentable {
    width: 100%;
    height: 64px;
    background-color: rgb(237, 237, 237);
    line-height: 64px;
    letter-spacing: inherit;
    font-weight: bold;
    font-size: 1.8rem;
    transition: .5s ease;
  }
  &__opentable:hover {
    background-color: rgb(196, 196, 196);
  }
  &__table {
    padding: 1.6rem;
    background-color: #fff;
  }
  &__table >tbody >tr >td {
    padding: 2.4rem;
  }
  &__innertable01 {
    margin-top: 1.6rem;
    background-color: rgba(0, 0, 0, .05);
  }
  &__innertable02 {
    margin-bottom: 1.6rem;
    border: 1px solid transparent;
    background-color: rgba(0, 0, 0, .05);
    white-space: nowrap;
    text-align: center;
  }
  &__innertable01 >tbody >tr >td,
  &__innertable02 >thead >tr >th,
  &__innertable02 >tbody >tr >td {
    padding: .8rem 1.6rem !important;
  }
  &__innertable02 >thead >tr >th,
  &__innertable02 >tbody >tr >td {
    border: 1px dashed rgba(0,0,0,.1);
  }

  &__singlebtn {
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
  }
  &__btns-item {
    margin-top: 4rem;
    color: #333;
  }
}