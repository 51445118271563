.c-table {
  width: 100%;
  border-collapse: collapse;
  >tbody >tr:not(:first-child) >td {
    padding-top: 1.6rem;
  }
  >tbody >tr:not(:last-child) >td {
    padding-bottom: 1.6rem;
    border-bottom: 1px dashed rgba(0,0,0,.1);
  }
  >tbody >tr >td:last-child {
    width: 100%;
  }
  &__head {
    padding-right: 3.2rem;
    font-weight: bold;
    white-space: nowrap;
  }
  &__head[data-th--top] {
    vertical-align: top;
  }
  &__repletion {
    font-size: 1.2rem;
    color: rgba(0,0,0,.5);
  }
  &__inline {
    display: inline-block;
  }

  &__subtable {
    border-collapse: collapse ;
  }
  &__subtable td:first-child {
    vertical-align: top;
    white-space: nowrap;
  }

  &[data-table--bg] {
    padding: 2.4rem;
    border-collapse: separate;
    background-color: #fff;
  }

  &[data-deco] {
    position: relative;
  }
  &[data-deco]:before {
    content: "";
    position: absolute;
    left: calc(.5em + -1px);
    top: 1rem;
    width: 2px;
    height: calc(100% - 3rem);
    background-color: rgb(49, 136, 201);
    background-color: rgb(49, 136, 201);
  }
  &[data-deco] td {
    border-bottom: none !important;
  }
  &[data-deco] .c-table__head {
    position: relative;
  }
  &[data-deco] .c-table__head:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    margin-right: 1.6rem;
    margin-top: -.4rem;
    border-radius: 50%;
    background-color: rgb(49, 136, 201);
  }
}