.c-deco {
  display: flex;
  align-items: center;
  &:before {
    content: "";
    width: .3em;
    height: 1em;
    margin-right: .8rem;
  }
  &[data-deco--blue]:before {
    background-color: rgb(49, 136, 201);
  }
  &[data-deco--white]:before {
    background-color: #fff;
  }
}

_:-ms-lang(x), .c-deco:before {
  margin-top: -.5rem;
}