.p-personal {
  position: relative;
  overflow: hidden;
  &__intro {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &__intro-name {
    display: block;
    font-size: 4rem;
  }
  &__intro-summary {
    display: block;
    line-height: 1.2;
  }
  &[data-person--nameleft] >.p-personal__intro {
    left: auto;
    right: 55%;
  }
  &[data-person--nameright] >.p-personal__intro {
    left: 55%;
    right: auto;
  }
}