.pg-education-step {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  &:before, &:after {
    position: absolute;
  }
  &:before {
    content: url(../../images/recruit/recruit-edu-arrow01.svg);
    top: 50px;
    left: 0;
  }
  &:after {
    content: url(../../images/recruit/recruit-edu-arrow02.svg);
    bottom: 50px;
    right: 0;
  }
  &__block {
    width: 70%;
    padding: 2.4rem 3.2rem;
    text-align: center;
  }
  &__block[data-esb--orange] {
    align-self: flex-end;
    background-color: #ff931e
  }
  &__block[data-esb--green] {
    align-self: center;
    background-color: #7ac943
  }
  &__block[data-esb--blue] {
    background-color: #3fa9f5
  }
  &__block-text {
    margin-bottom: 2.4rem;
    color: #fff;
  }
  &__block-label {
    width: 216px;
    margin-left: auto;
    margin-right: auto;
    padding-top: .8rem;
    padding-bottom: .8rem;
    font-weight: bold;
    font-size: 2rem;
  }
  &__block[data-esb--orange] >.pg-education-step__block-label {
    color: #ff931e
  }
  &__block[data-esb--green] >.pg-education-step__block-label {
    color: #7ac943
  }
  &__block[data-esb--blue] >.pg-education-step__block-label {
    color: #3fa9f5
  }
}