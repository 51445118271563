.p-floatbtn {
  position: fixed;
  right: 3.2rem;
  bottom: 3.2rem;
  z-index: 2;
  width: 4.8rem;
  height: 4.8rem;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 50%;
  background: url(../../images/btn-pagetop.svg) no-repeat center/ cover;
  font-size: 0;
  transition: .5s ease-out;
  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    transform: scale(1.1) translateY(-2px);
  }
}