.c-bglabel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  border-radius: 3px;
  text-align: center;
  &[data-bg--white] {
    background-color: rgb(255,255,255);
  }
  &[data-bg--gray] {
    background-color: rgba(0,0,0,.1);
  }
}