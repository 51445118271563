.p-pagetitle {
  margin-top: -10px;
  padding: 1.6rem 0;
  border-top: 10px solid rgb(49, 136, 201);
  background-color: rgb(64, 150, 212);
  letter-spacing: .5rem;
  color: #fff;
  &__label {
    margin-bottom: .8rem;
  }
  &__submenu {
    margin-right: .8rem;
    font-size: 1.8rem;
  }
  &__submenu:before {
    background: url(../../images/icon-arrow-white.svg) no-repeat center/cover;
  }
}

_:-ms-lang(x), .p-pagetitle__submenu:before {
  margin-top: -.1rem;
}