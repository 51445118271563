.l-header {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.6rem;
  &__logo {
    margin-right: auto
  }
  &__nav {
    display: flex;
    overflow: hidden;
    width: 100%;
    margin-top: .8rem;
    border-radius: 3px 3px 0 0;
    list-style: none;
  }
  &__nav >li {
    position: relative;
    width: 20%;
  }
  &__nav >li:not(:last-child) {
    border-right: 1px solid #fff;
  }
  &__nav-target {
    width: 100%;
    display: inline-block;
    min-width: 180px;
    height: 64px;
    background-color: rgb(49, 136, 201);
    line-height: 64px;
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;
    color: #fff;
    transition: .5s ease;
    &:hover {
      background-color: rgb(64, 150, 212);
    }
  }
  &__nav-target.is-current {
    background-color: rgb(64, 150, 212);
  }
}