.pg-works-detail {
  &__block {
    width: 100%;
    background-color: #fff;
  }
  &__block:not(:last-child) {
    margin-bottom: 1.6rem;
  }
  &__block-head {
    padding: 1.6rem 2.4rem;
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
  &__block-body {
    display: flex;
  }
  &__block-body__photo {
    width: 30%;
    border-right: 1px solid rgba(0,0,0,.1);
  }
  &__block-body__photo >img {
    width: 100%;
  }
  &__block-body__list {
    width: 70%;
    padding: 1.6rem;
  }
  &__block-body__list-item {
    display: inline-block;
    margin: .8rem;
    padding: .8rem 1.6rem;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 3px;
    background-color: rgba(0, 0, 0, .05);
    transition: .5s ease;
  }
  &__block-body__list-item[data-wd-target] {
    cursor: pointer;
    background-color: rgb(64, 150, 212);
    color: #fff;
  }
  &__block-body__list-item[data-wd-target].is-active,
  &__block-body__list-item[data-wd-target]:hover {
    background-color: rgb(252, 178, 40);
    color: #fff;
  }
}