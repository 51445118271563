.c-btn {
  display: inline-block;
  min-width: 180px;
  height: 48px;
  padding: 0 2.4rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 3px;
  background-color: rgb(49, 136, 201);
  line-height: 48px;
  text-align: center;
  letter-spacing: inherit;
  color: #fff;
  transition: .5s ease;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: rgb(252, 178, 40);
  }
  &[data-btn--basewhite] {
    background-color: #fff;
  }
  &[data-btn--iconpdf] {
    position: relative;
    padding-left: calc(2.4rem + 1em + .8rem);
  }
  &[data-btn--iconpdf]:before {
    content: "";
    position: absolute;
    left: 2.4rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5em;
    height: 1.5em;
    margin-right: .8rem;
    background: url(../../images/icon-pdf.svg) no-repeat center/ contain;
  }
  &[data-btn--onimg] {
    padding-top: .8rem;
    padding-bottom: .8rem;
    line-height: normal;
  }
}