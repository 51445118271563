.c-title {
  font-weight: bold;
  &[data-title--fs40] {
    font-size: 4rem;
    line-height: 1.3;
    color: rgb(49, 136, 201);
  }
  &[data-title--fs32] {
    font-size: 3.2rem;
    line-height: 1;
    color: rgb(49, 136, 201);
  }
  &[data-title--fs25] {
    font-size: 2.5rem;
  }
  &[data-title--fs20] {
    font-size: 2rem;
  }
  &[data-title--fs18] {
    font-size: 1.8rem;
  }
  &[data-title--white] {
    color: #fff;
  }
  &[data-title--blue] {
    color: rgb(49, 136, 201);
  }
}